import { invokeApi } from "../../bl_libs/invokeApi";

export const TeachersListing = async (id) => {
  const requestObj = {
    path: `api/teacher/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddTeachers = async (data) => {
  const requestObj = {
    path: `api/teacher`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteTeachers = async (id) => {
  const requestObj = {
    path: `api/teacher/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditTeachers = async (id, data) => {
  const requestObj = {
    path: `api/teacher/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const TeachersDetail = async (moment_slug) => {
  const requestObj = {
    path: `api/moment/detail_magic_moment/${moment_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
